.padre-info {
  width: 100%;
  height: 100vh;
  position: relative;
  
  scroll-margin-top: 8ex;
}

.cont-info {
 
  padding-top: 6%;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.div-info {
  display: flex;
  justify-content: space-between;
  margin-top: 3%;
  width: 60%;
  height: auto;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 2px 1px 15px 4px rgba(0, 0, 0, 0.75);
  background-color: rgb(255, 255, 255);
  padding-right: 3%;
}
.foto {
  padding: 2% 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px 0px 0px 10px;
  width: 40%;

  height: auto;
  background-color: rgb(97, 97, 97);
  /* background-color: #496782; */
}
.foto img {
  width: 70%;
  margin: 10% 0;
}
.foto h4 {
  color: rgb(255, 255, 255);

  padding: 1%;
  border-radius: 10px 10px 10px 10px;
}
#age {
  display: block;
  justify-content: left;
}
.cont-texto {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 30vw;
  height: 60vh;
  padding: 2% 2%;
}
.cont-texto p{
  font-size: larger;
}
#texto1 {
  
  /* animacion hecha con https://www.theappguruz.com/tag-tools/web/CSSAnimations/ */
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.btnDownload1{
  text-align: center;
}
.btnDownload1 button{
  width: 8vw;
  height:4vh;
  /* background-color: #1c683b; */
  background-color: #496782;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  border: none;
  border-radius: 10px;
  margin-bottom: 2%;
  box-shadow: 0px 0px 20px -1px rgba(0, 0, 0, 0.478);
  
}

#texto2,
#texto3 {
  /* animacion hecha con https://www.theappguruz.com/tag-tools/web/CSSAnimations/ */
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: none;
}

.texto h1 {
  margin-bottom: 6%;

  text-align: center;
}

.btn-sig {
  margin-top: 2%;
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
}
.btn-sig img {
  display: flex;
  width: 30px;
}

.div-info-responsive {
  display: none;
}

.btn-modal{
  display: none;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.contInfoRes{
  display: none;
}


@media (max-width: 600px ) and (min-height: 200px ) {
  .padre-info{
    scroll-margin-top: 0;
    height: auto;
    
  }
.cont-info{
  display: none;
}

.contInfoRes{
  scroll-margin-top: 1ex;
  display: block;
  width: 100vw;
  min-height: 400px;
  display: flex; flex-direction: column; align-items: center; justify-content: space-between;

background-color: #fff;
padding: 15% 0;

}



.contInfoScroll{
 
width: 100vw;

  padding: 5%;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  align-items: center;
  
  scroll-snap-type: x mandatory;

}
.contInfoRes p, h4{
  line-height:2em;
  text-align: center;
  display: flex;
 
}
.contInfoRes img{
 
}


.contInfoScroll::-webkit-scrollbar{
  background-color: transparent;
}

.contInfoScroll .infoTextoRes{
  /* scroll-margin-top: 25ex; */
  
padding: 3% 5%;
  margin-top: 6%;
  min-width: 90vw;
  height: auto;
 margin: 2%;
scroll-snap-align: center;
  
}

.btnDownload button{
  width: 40vw;
  height:6vh;
  /* background-color: #1c683b; */
  background-color: #496782;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  border-radius: 10px;
  border: none;
  box-shadow: 0px 0px 20px -1px rgba(0, 0, 0, 0.478);
  
}

.infiBtnPagSig img{
  margin-top: 5vh;
  width: 10vw;
  filter: opacity(30%);

    
    animation-name: arriba-Abajo;
     animation-duration: 2s;
     animation-iteration-count: infinite;
}

}

