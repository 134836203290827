* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.contenedor {
  position: relative;
  height: 100vh;
  /* background-color: #496782; */
}
.video-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -3;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgb(74, 73, 71);
}
.video-container video {
  position: relative;
  opacity: 20%;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.contenido-portada {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.contenido-portada h1 {
  color: white;
  font-size: 150px;
}

.contenido-portada h4 {
  color: rgb(213, 206, 206);
  font-size: 50px;
  font-weight: 900;
}
.titulos-portada {
  width: 60%;
  margin-bottom: 2%;
  text-align: center;
}

.botones-portada {
  text-align: center;
  width: 100%;
  height: auto;
}
.botones-portada a {
  text-decoration: none;
  cursor: pointer;
  padding: 1% 2%;
  color: #cccdd4;
  font-size: 150%;
  font-weight: 600;
  background: none;
  border: none;
  transition: 0.5s;
  border-radius: 50px 50px 50px 50px;
}
.botones-portada a:hover {
  font-size: 25px;
  font-weight: 300;
  padding: 5px 2%;
  color: rgb(65, 64, 64);
  background: white;
}
.btn-responsive {
  display: none;
}

.padre-inicio-res {
  display: none;
}
@media (max-width: 600px ) and (min-height: 200px ) {
  .contenedor {
    display: none;
  }
  .padre-inicio-res {
    display: flex; flex-direction: column;
    align-items: center;
    width: 100vw;
    height: auto;
    color: white;
   
    
  }
  .video-container video{
    display: none;
  }
  .video-container{
    min-height: 1000px;
    max-height: 1001px;
    width: 100vw;
    
   
  }
  
  .header-inicio-res {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 9vh;
    width: 100vw;
    padding: 0 7%;
    /* box-shadow: 2px 5px 5px 0px rgba(196,192,196,1); */
  }
  .header-inicio-res h1 {
    font-family: "Courgette", cursive;
  }
  .container-foto-res {
    max-width: 60%;
    max-height: 30%;
    margin-top: 5%;
    margin-bottom: 5%;
    position: relative;
  }

  .container-foto-res img {
    z-index: 3;
    
    
  width: 100%; height: 100%;
  object-fit: fill;
  border: 10px solid #343030;
  border-radius: 50%;
    
  }


  .txtiniciores {
   
    width: 100vw;
    height: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .cont-btn-inicio-res {
    width: 100vw;
    min-height: 300px;
  
    display: flex; flex-direction: column; justify-content: center;
    
  }
  .bnt-inicio-res {
    width: 100vw;
    min-height:8vh;
    display: flex; justify-content: space-around;
    padding: 0% 12%;
    
  }
  .bnt-inicio-res button{
    width: 48%;
    height: auto;
    border: solid 3px white;
    
    margin: 2% 0;
    background: none;
    border-radius: 42px 42px 42px 42px;
    
    
  }
  .bnt-inicio-res a{
    font-size: larger;
    color: white;
    text-decoration: none;
  }

 

  @keyframes arriba-Abajo {
    0%{
      transform: translateY(10px);
    }
    50%{
  transform: translateY(-10px);
    }
    100%{
      transform: translateY(10px);
    }
  }
  
}
