.padre-contact {
    width: 100%;
    height: 90vh;
    position: relative;
    /* background: linear-gradient(0deg,#161515 0%, #dfdfdf 80%); */
    background-color: #808080;
    /* background-color: #5a7b98; */
    /* background-image: url(../image/fondoContacto.jpg); */

    /* background-size ajusta el tamaño de la imagen de fondo al div */
    /* background-size: 100% 100%;  */
    
    margin-top: 11%;
    scroll-margin-top: 10ex;
  }
  
  
  .cont-contact{
   padding-top: 3%;
  
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  
  }
 
  form{
    width: 30vw;
    height: 70vh;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2% 0;
    margin-top: 2%;
    border-radius: 25px 25px 25px 25px;
    box-shadow: 0px 2px 15px 7px rgba(0,0,0,0.14);
   
  }
  form input, textarea{
    width: 70%;
    background: none;
    margin: 1% 5%;
    border: none;
    box-shadow: 0px 2px 15px 7px rgba(0,0,0,0.14);
    text-decoration: none;
    /* este outline sirve para que cuando se seleccione el input no se vea el cuadro blanco alrededor */
    outline: none;
    color: rgb(56, 56, 56);
    border-radius: 30px 30px 30px 30px;
    padding: 1% 0 0 3%;
  }
  form input{
    height: 18%;
    
  }
  form textarea{
    padding: 2% 3%;
    height: 50%;
    font-family: 'Poppins', sans-serif;
  }
  form h1, p{
    color: #808080;
        }
#btnCaptcha{
  margin-top: 2%;
}
  #btnEnviar{
    width: 20%;
    
    margin: 3% 0;
  }
  #enviar:hover{
    background-color: #808080;
  }
  #ContactTemporal{
   
    display: none;
    color: #ffffff;
  }

  @media (max-width: 600px ) and (min-height: 200px ){
    
    .cont-contact{
      height: auto;
      width: 100vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 5vh 0;
    }
    .padre-contact{
      display: flex; align-items: center;
      scroll-margin-top: 0;
      height: auto;
      padding: 10% 0;
    }
   
    form{
      min-width: 90vw;
      min-height: 700px;
      padding: 20px 3px;
      
    }
    
    

    form textarea{
      min-width: 80%;
      min-height: 8vh;
     
      margin: 3% 0;
      
    }
    form input{
      min-width: 80%;
      min-height: 8vh;
      max-height: 8vh;
      margin: 3% 0;
      
    }

    #btnEnviar{
      min-width: 40%;
      min-height: 8%;
      max-height: 15%;
      margin-bottom: 2vh;
    }
  }